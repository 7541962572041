.login-wrapper{
  background-image: url('../public/assets/images/rx_fifth.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  padding: 0 12%;
}

@media only screen and (max-width: 600px) {
  .login-wrapper {
    background-image: url('../public/assets/images/mob_rx_back.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }
}

.property-card-wrapper .css-1lmvy6-MuiLinearProgress-root{
  height: 6px;
}

.property-card-wrapper .css-j46ne6-MuiLinearProgress-bar1{
  background-color: #ff382d !important;
}

.css-1xpekmo-MuiDivider-root{
  margin-top: 14px;
    margin-bottom: 5px;
}

.property-card{
    background: white;
    box-shadow: 1px 0px 7px 3px #8080801c;
}


@media (min-width: 900px){
  .css-cho4m2-MuiButtonBase-root-MuiButton-root {
    font-size: 14px;
    padding: 12px 12px;
  }
}

.share-button{
  position: relative;
}

.socal-media-icon{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 2px 2px 5px 1px gray;
  margin-right: 10px;
  background: white;
  cursor: pointer;
  transition: transform 2s; /* Reduce the duration to 0.5 seconds */
}

.socal-media-icon:hover{
 zoom: 1.1;
}

.share-options{
  position: absolute;
  top: 40px;
  z-index: 1;
  left: 0;
}

.css-x4kqkr {
  background-color: #585858;
}

.adharNoWrapper .css-1qaralg-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: 22px;
    max-width: 170px;
    min-width: auto;
}

.react-international-phone-country-selector-button{
  padding: 24px 12px;
}

.MuiCardContent-root {
  padding:  15px;
}

.customOptintextfield .MuiOutlinedInput-root fieldset {
  border-color: #a6acaf; /* Default border color */
}

.customOptintextfield .MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #909497; /* Hover border color */
}