

.citizenshipQuestion {
    color: #252525;
    font-weight: 400;
    font-size:0.9rem !important;
}

.citizenshipToggleBtn{
    padding: 4px 20px;
    border-radius:5px;
    cursor: pointer;
}


.citizenshipToggleBtnSelected {
    background-color: #ff382d !important;
    color: #FFF !important;
}

