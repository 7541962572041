.relax-select-logo {
  width: 30%;
  margin-top: 10px;
}

.sub-title {
  font-family: 1.375rem;
  font-style: italic;
}

.pay-titles {
  font-size: 2.5rem;
  font-weight: bold;

  .stikethrough {
    text-decoration: line-through;
    color: #dc342f;
  }
  .price-label {
    padding-left: 7px;
  }
}

.select-title {
  font-size: 1.15rem;
  font-style: normal;
}

.relax-select-logo-main {
  width: 70%;
  margin-top: 10px;
  margin-bottom: 30px;
}

.select-benifit-list {
  margin-top: 20px;
  margin-left: 25px;
}


.member-valid{
  margin-top: 0px;
  font-style: normal;
  color: black;
  font-size: 15px;
}